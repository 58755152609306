<template>
	<div>
		<div
			class="BpForms BpWysiwyg"
			:class="stateComputed.currentClasses"
			:data-testid="id + '-input'">
			<label
				v-if="state.label"
				class="BpForms--label"
				:for="state.id">
				{{ state.label }}
				<span
					v-if="state.tooltip"
					class="Tooltip Tooltip-bottom"
					:data-tooltip="state.tooltip"
					tabindex="0"
					data-testid="blueprint-input-tooltip"
					><span class="Tooltip-icon BpForms--tooltip"></span
				></span>
				<span
					class="BpForms--optional"
					data-testid="forms-optional-label">
					{{ language.string.cForms.optional }}
				</span>
			</label>

			<div
				:id="'wysiwygEditor-' + randomId"
				ref="qlContainer"
				class="BpWysiwyg--container StandardHtml"></div>

			<!-- <input
				:id="state.id"
				ref="inputRef"
				v-model="state.value"
				class="BpForms--input BpInput--input"
				:class="`Text-${state.textAlign}`"
				:name="state.id"
				:required="state.required"

				:aria-required="state.required"
				data-testid="blueprint-input-input"
				@blur="onBlur"
				@focus="onFocus"
				@input="updateValue(true)"
				@change="updateValue()" /> -->

			<!-- <quill-editor
				ref="inputRef"
				v-model:content="state.value"
				content-type="html"
				theme="snow"
				:read-only="state.disabled"
				:toolbar="toolbarOptions"
				:modules="modules"
				@blur="onBlur"
				@focus="onFocus"
				@text-change="updateValue(true)" /> -->

			<transition name="slide-down">
				<div
					v-if="internal.currentMsg"
					class="BpForms--msg"
					data-testid="blueprint-input-msg"
					:data-msgtype="internal.currentMsgType">
					{{ internal.currentMsg }}
				</div>
			</transition>
		</div>

		<blueprint-popup
			ref="popupMediaUploader"
			header="Upload your media">
			<template #default>
				<p>
					Upload a file from your computer, provide a description, and it will be
					incorporated into your HTML content.
				</p>
				<blueprint-upload
					id="mediaUploaderFile"
					:ref="popupFields.file"
					v-model:value="popupData.file"
					:min="1"
					:max="1"
					:max-size="209715200"
					:file-types="
						popupData.type === 'image' ? '.jpg,.jpeg,.png,.bmp' : '.mp4,.avi,.mpg,.mov'
					"
					required />

				<blueprint-input
					v-if="popupData.type === 'image'"
					id="mediaUploaderAlt"
					:ref="popupFields.alt"
					v-model:value="popupData.alt"
					placeholder="Description " />

				<div class="Text-center">
					<button
						class="Btn"
						:class="popupData.saving && 'Btn-loading'"
						@click="!popupData.saving && addMedia()">
						Save & insert into content
					</button>
				</div>
			</template>
		</blueprint-popup>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';
	import * as Helpers from './helpers.js';

	import Quill from 'quill';
	import 'quill/dist/quill.snow.css';
	import './Wysiwyg-formats.js';

	import htmlEditButton from 'quill-html-edit-button';
	import MagicUrl from 'quill-magic-url';
	import PasteSmart from 'quill-paste-smart';
	import BlotFormatter2 from '@enzedonline/quill-blot-formatter2';
	import '@enzedonline/quill-blot-formatter2/dist/css/quill-blot-formatter2.css';

	export default {
		name: 'BlueprintWysiwyg',

		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {string | number | null | undefined} [value] - bind value
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string | number | null | undefined} [value] - bind value
			 */
			value: {
				type: [String, Number, null, undefined],
				required: false,
				default: undefined
			},

			/**
			 * property {string} uploadType - upload item's id
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} uploadType - upload item's id
			 */
			uploadType: {
				type: String,
				required: true
			},

			/**
			 * property {object} uploadData - extra object with data needed for to upload files
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {object} uploadData - extra object with data needed for to upload files
			 */
			uploadData: {
				type: Object,
				required: true
			},

			/**
			 * property {string} [label] - label, or title
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} [label] - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: undefined
			},
			/**
			 * property {boolean} [disabled] - should this field be "disabled"
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {boolean} [disabled] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},
			/**
			 * property {string} [simpleVersion] - less cluttered version of the editor
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} [simpleVersion] - less cluttered version of the editor
			 */
			simpleVersion: {
				type: Boolean,
				required: false,
				default: false
			},
			/**
			 * property {boolean} [required=false] - is this field required?
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {boolean} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},
			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: undefined
			},
			/**
			 * property {Function} [onFocus=noop] - function to be called when field is focused
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {Function} [onFocus=noop] - function to be called when field is focused
			 */
			onFocus: {
				type: Function,
				required: false,
				default: function () {}
			},
			/**
			 * property {Function} [onBlur=noop] - function to be called when blur event happens
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {Function} [onBlur=noop] - function to be called when blur event happens
			 */
			onBlur: {
				type: Function,
				required: false,
				default: function () {}
			},
			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_Wysiwyg
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		//  ---------- SETUP ----------
		setup(props, context) {
			const language = useLanguageStore();
			const inputRef = Core.Vue.ref(null);
			// reactive props, that's all
			const state = Core.Vue.computed(() => {
				return {
					...props // make all props reactive,
				};
			});

			// internal values only (can be set from inside or outside)
			const internal = Core.Vue.reactive({
				currentMsg: state.value.msg,
				currentMsgType: state.value.msgType
			});

			// ----------------------- QUILL EDITOR CONFIG AND STUFF -----------------------
			const popupMediaUploader = Core.Vue.ref();
			let quill;
			let toolbarOptions;
			const qlContainer = Core.Vue.ref();
			const randomId = Core.Vue.ref(Core.Utils.makeid(5));
			const popupData = Core.Vue.reactive({
				type: null,
				cursor: null,
				file: null,
				alt: null,
				saving: false
			});
			const popupFields = {
				file: Core.Vue.ref(),
				alt: Core.Vue.ref()
			};

			// toolbar options
			if (props.simpleVersion) {
				toolbarOptions = [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					['bold', 'italic', 'underline', 'strike'],
					[{ color: [] }],
					[{ align: [] }, { indent: '-1' }, { indent: '+1' }],
					[{ list: 'ordered' }, { list: 'bullet' }],
					['link', 'image'],
					['clean'] // remove formatting button
				];
			} else {
				toolbarOptions = [
					[{ header: [1, 2, 3, 4, 5, 6, false] }],
					[
						'bold',
						'italic',
						'underline',
						'strike',
						{ script: 'sub' },
						{ script: 'super' }
					],
					[{ color: [] }, { background: [] }],
					[{ align: [] }, { indent: '-1' }, { indent: '+1' }],
					[{ list: 'ordered' }, { list: 'bullet' }, 'blockquote', 'code-block'],
					['link', 'image', 'video'],
					['clean'] // remove formatting button,
				];
			}

			Core.Vue.onMounted(() => {
				// register modules
				Quill.register({
					'modules/htmlEditButton': htmlEditButton,
					'modules/magicUrl': MagicUrl,
					'modules/pasteSmart': PasteSmart,
					'modules/blotFormatter2': BlotFormatter2
				});

				// set up quill editor
				quill = new Quill(`#wysiwygEditor-${randomId.value}`, {
					theme: 'snow',
					readOnly: state.value.disabled,
					modules: {
						toolbar: {
							container: toolbarOptions
						},
						magicUrl: true,
						pasteSmart: true,
						htmlEditButton: props.simpleVersion
							? false
							: {
									buttonHTML: '⌘'
								},
						blotFormatter2: {
							align: {
								allowAligning: true,
								alignments: ['left', 'center', 'right']
							},
							resize: {
								useRelativeSize: true,
								allowResizeModeChange: true,
								imageOversizeProtection: true
							},
							image: {
								registerImageTitleBlot: true,
								allowAltTitleEdit: false
							},
							video: {
								selector: 'video',
								registerCustomVideoBlot: false,
								registerBackspaceFix: true
							}
						}
					}
				});

				// overwrite default image and video handler
				quill.getModule('toolbar').addHandler('image', function () {
					showMediaUploader('image');
				});
				quill.getModule('toolbar').addHandler('video', function () {
					showMediaUploader('video');
				});

				// inject initial value
				quill.clipboard.dangerouslyPasteHTML(0, state.value.value);

				// remove some classes to not make noise!
				qlContainer.value.classList.remove('ql-container');
				qlContainer.value.classList.remove('ql-snow');

				// update value on change
				quill.on('text-change', () => {
					state.value.value = quill.root.innerHTML;
					context.emit('update:value', state.value.value);
				});
			});

			// our computed and transformed values to use as we need (from props)
			const stateComputed = Core.Vue.computed(() => {
				return {
					currentClasses: Helpers.resolveClassNames(state.value, internal)
				};
			});

			// allow update to internals from outside via props
			Core.Vue.watch(
				() => [props.msg, props.msgType],
				([msgNew, msgTypeNew]) => {
					internal.currentMsg = msgNew;
					internal.currentMsgType = msgTypeNew;
				}
			);

			/**
			 * Show media uploader popup
			 * @param {string} type - type of media to upload ('image' | 'video')
			 */
			function showMediaUploader(type) {
				popupData.cursor = quill.getSelection(true);
				popupData.type = type;
				popupMediaUploader.value.toggle();
			}

			/**
			 * Add selected image to the editor at selected cursor position
			 */
			async function addMedia() {
				popupData.saving = true;

				if (Core.Utils.validateFields(popupFields)) {
					let filePath = '';
					let fileSrc = '';

					// upload image
					if (popupFields.file.value?.filelist.length) {
						try {
							const uploadStatus = await popupFields.file.value.upload(
								`${Core.Config.BACKEND_API}school/wysiwyg-upload`,
								{
									type: props.uploadType,
									data: JSON.stringify(props.uploadData)
								}
							);

							// get the right name with relative path in DATA-FILEPATH or directly SRC for non-secure images
							filePath =
								props.uploadType === 'lessons'
									? `products/${props.uploadData.productId}/${props.uploadData.lessonId}/${uploadStatus[0].name}`
									: filePath;
							filePath =
								props.uploadType === 'assignments'
									? `products/${props.uploadData.productId}/${props.uploadData.lessonId}/${props.uploadData.assignmentId}/${uploadStatus[0].name}`
									: filePath;
							fileSrc =
								props.uploadType === 'courses' ||
								props.uploadType === 'articles' ||
								props.uploadType === 'downloads'
									? `${Core.Config.BUCKET_SCHOOL}/${props.uploadData.schoolId}/products/${props.uploadData.productId}/content/${uploadStatus[0].name}`
									: fileSrc;
							fileSrc =
								props.uploadType === 'pages'
									? `${Core.Config.BUCKET_SCHOOL}/${props.uploadData.schoolId}/pages/${props.uploadData.pageId}/content/${uploadStatus[0].name}`
									: fileSrc;
						} catch (error) {
							Core.Event.trigger('NOTIFICATIONS', {
								type: 'danger',
								content: 'Sorry, something went wrong with the image upload.',
								icon: 'iconFont-cross'
							});
							popupData.saving = false;
							popupMediaUploader.value.toggle();
							return;
						}

						// ADD IMAGE
						if (popupData.type === 'image') {
							quill.insertEmbed(popupData.cursor.index, 'image', {
								alt: popupData.alt,
								'data-filePath': filePath,
								src: fileSrc
							});
						}

						// // ADD VIDEO
						if (popupData.type === 'video') {
							quill.insertEmbed(popupData.cursor.index, 'video', {
								'data-filePath': filePath,
								src: fileSrc
							});
						}
					}

					popupMediaUploader.value.toggle();

					// reset popupData
					popupData.type = null;
					popupData.cursor = null;
					popupData.file = null;
					popupData.alt = null;
				}

				popupData.saving = false;

				// ADD AUDIO
				// TODO: later :)
			}

			/* -------------------- UPDATE & VALIDATE FUNCTIONALITY ---------------------- */
			/**
			 * updateValue the value from input, validate if needed
			 * @param {boolean} [delayed=false] if we need to debounce, set to true
			 * @returns {boolean} false if validation failed
			 */
			function updateValue(delayed = false) {
				state.value.value = state.value.value.trim();
				context.emit('update:value', state.value.value);

				return true;
			}

			/**
			 * validate the field
			 * @returns {boolean} validation result
			 */
			function validate() {
				return Helpers.validators(state, internal, inputRef);
			}

			/**
			 * Reset field to initial state
			 *
			 */
			function reset() {
				Helpers.resetAll(internal, state);

				//Let the component where its used to be aware of the reset value
				context.emit('update:value', state.value.value);
			}

			return {
				state,
				stateComputed,
				internal,
				language,
				inputRef,
				updateValue,
				validate,
				reset,
				toolbarOptions,
				randomId,
				qlContainer,
				popupMediaUploader,
				popupData,
				popupFields,
				addMedia
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@import './index.scss';
	@include block('BpWysiwyg') {
		@include element('input') {
			height: auto;
		}

		@include element('container') {
			border: 1px solid var(--color-grey700);
			border-top: none;

			& .ql-hidden {
				display: none;
			}
		}
	}
</style>
